var temp
let intialState = {
    assignedSubjects: [],
    isCountLoading: true,
    isBubbleLoading: true,
    resources: [],
    getIndividualResource: {},
    topicDetails: {},
    questionIds: [],
    question: {},
    questions: [],
    filterPrevResources: [],
    isShow: false,
    remainQuestion: [],
    isSubjectLoading: true,
    semsArr: [],
    showQuiz11: false
}

export default function syllabusReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case "Reset_Subjects":
            stateCopy.assignedSubjects = [];
            stateCopy.isSubjectLoading = true;
            return stateCopy;
        case "Get_Assigned_Subject":
            stateCopy.assignedSubjects = action.payload;
            stateCopy.isSubjectLoading = false;
            return stateCopy;
        case "Get_Assigned_Subject_list":
            stateCopy.assignedSubjects = [...stateCopy.assignedSubjects, action.payload];
            if (stateCopy.assignedSubjects === null) {
                stateCopy.isempty = true;
            }
            stateCopy.assignedSubjectsCopy = stateCopy.assignedSubjects;
            stateCopy.isSubjectLoading = false;
            return stateCopy
        case "Get_Syllabus_Semsters":
            stateCopy.program_id = action.payload.id;
            stateCopy.semsArr = [...stateCopy.semsArr, action.payload.data];
            stateCopy.isSubjectLoading = false;
            return stateCopy;
        case 'Reset_Semster':
            stateCopy.semsArr = [];
            return stateCopy;
        case "Get_Assigned_Subject_Bubble_details":
            stateCopy.details = action.payload;
            stateCopy.isCountLoading = false;
            return stateCopy;
        case "Get_Assigned_Subject_Bubble":
            stateCopy.assignedSubjectBubble = action.payload.sort((a, b) => (a.index > b.index) ? 1 : -1);
            stateCopy.isBubbleLoading = false;
            return stateCopy;
        case "Reset_Count_Loading":
            stateCopy.isCountLoading = true;
            return stateCopy;
        case "Get_Subject_Resources":
            var topics = [...new Set(action.payload.data.sort((a, b) => (a.index > b.index) ? 1 : -1).map(el => el.topic))].map(topic => ({ topic, complete: false }));
            const content = topics.map(data => { return action.payload.data.sort((a, b) => (a.index > b.index) ? 1 : -1).filter(el => { return data.topic === el.topic }) });
            stateCopy.topics = getComplete(topics, content);
            stateCopy.resourcesCopy = action.payload.data;
            stateCopy.resourceIndex = [].concat.apply([], content);
            stateCopy.resources = content;
            stateCopy.isLoading = false;
            stateCopy.topicsDetails = action.payload;
            stateCopy.isCountLoading = false;
            return stateCopy;
        case "Clear_Details":
            stateCopy.isBubbleLoading = true;
            stateCopy.isCountLoading = true;
            return stateCopy;
        case "Get_Syllabus_Individual_Resource":
            stateCopy.getIndividualResource = action.payload;
            var found1 = stateCopy.filterPrevResources.find(o => o._id === action.payload._id);
            if (found1 === undefined) {
                stateCopy.filterPrevResources = [...stateCopy.filterPrevResources, stateCopy.getIndividualResource];
            }
            stateCopy.isShow = true;
            if (action.payload.type === 'Quiz') {
                stateCopy.questions = action.payload.questions;
                stateCopy.qIndex = stateCopy.questions.findIndex(el => { return el.answered === null });
            }
            stateCopy.showQuiz11 = false;
            return stateCopy;
        case "Syllabus_reset_index":
            stateCopy.getIndividualResource = {};
            stateCopy.questions = [];
            stateCopy.isShow = false;
            stateCopy.qIndex = stateCopy.questions.findIndex(el => { return el.answered === null });
            stateCopy.showQuiz1 = false;
            return stateCopy;
        case "Reset_Topic_Details":
            stateCopy.details = [];
            stateCopy.resourcesCopy = [];
            stateCopy.resources = [];
            stateCopy.isCountLoading = true;
            return stateCopy;
        case "Get_Incomplete":
            if (!action.payload.checked) {
                const assignesSub = stateCopy.assignedSubjects.filter(items => items.el._id === action.payload.id)[0];
                const data = assignesSub.data.filter(el => el.completion_date == null);
                stateCopy.assignedSubjects.forEach(answer => {
                    if (answer.el._id === action.payload.id) {
                        answer.data = data
                    }
                });
            }
            else {
                stateCopy.assignedSubjects = stateCopy.assignedSubjectsCopy;
            }


            return stateCopy;
        case "Get_Resource_Notes":
            if (action.payload.data !== null) {
                stateCopy.note = action.payload.data.note;
            }
            else {
                stateCopy.note = null;
            }
            return stateCopy;

        case 'Resource_Analytic_capture':
            const analytics = stateCopy.resourcesCopy.map(answer => {
                if (answer._id === action.payload.r_id) return {
                    _id: answer._id,
                    title: answer.title,
                    source: answer.source,
                    type: answer.type,
                    skill: answer.skill,
                    liked: answer.liked,
                    topic: answer.topic,
                    image: answer.image,
                    index: answer.index,
                    duration: answer.duration,
                    viewed: answer.viewed ? true : action.payload.viewed,
                    recommended: answer.recommended
                }
                return answer
            })
            stateCopy.resourcesCopy = analytics;
            const content1 = stateCopy.topics.map(data => { return stateCopy.resourcesCopy.sort((a, b) => (a.index > b.index) ? 1 : -1).filter(el => { return data.topic === el.topic }) });
            stateCopy.topics = getComplete(stateCopy.topics, content1);
            stateCopy.resources = content1;
            return stateCopy;
        case "Get_Resource_Previous_Index":
            stateCopy.getIndividualResource = stateCopy.filterPrevResources.filter(el => el.id === action.payload._id.toString())[0];
            return stateCopy;
        case 'Get_Resource_Questions':
            stateCopy.question = stateCopy.questions.filter(el => el._id === action.payload._id)[0];
            stateCopy.showQuiz1 = true;
            stateCopy.remainQuestion = [];
            return stateCopy;
        case "Get_Resource_Answer":
            stateCopy.question.correct = action.payload.data.correct;
            stateCopy.question.correct_id = action.payload.data.correct_id;
            stateCopy.question.answered = action.payload.aid;
            const answersns = stateCopy.questions.map(answer => {
                if (answer._id === action.payload.qid) return {
                    _id: answer._id,
                    title: answer.title,
                    description: answer.description,
                    type: answer.type,
                    skill: answer.skill,
                    liked: answer.liked,
                    answers: answer.answers,
                    correct_id: action.payload.data.correct_id,
                    correct: action.payload.data.correct,
                    answered: action.payload.aid
                }
                return answer
            })
            stateCopy.questions = answersns;
            return stateCopy;
        case "Resource_Quiz_Submit":
            stateCopy.isQuizComplete = true;
            stateCopy.questions.forEach((v, i) => {
                if (v.answered == null) {
                    stateCopy.remainQuestion = [...stateCopy.remainQuestion, i];
                }
                else {

                }
            });
            return stateCopy;
        case "Resource_Save_Score":
            stateCopy.isQuizComplete = false;
            const questions1 = stateCopy.questions.map(answer => {
                return {
                    _id: answer._id,
                    title: answer.title,
                    description: answer.description,
                    type: answer.type,
                    skill: answer.skill,
                    answers: answer.answers,
                    correct_id: null,
                    correct: null,
                    answered: null
                }
            })
            stateCopy.questions = questions1;
            stateCopy.showQuiz1 = false;
            return stateCopy;
        case "Resource_Close_Modal":
            stateCopy.isQuizComplete = false;
            stateCopy.remainQuestion = []
            return stateCopy;
        case "Resource_Retake_Quiz":
            const questions = stateCopy.questions.map(answer => {
                return {
                    _id: answer._id,
                    title: answer.title,
                    description: answer.description,
                    type: answer.type,
                    skill: answer.skill,
                    answers: answer.answers,
                    correct_id: null,
                    correct: null,
                    answered: null
                }
            })
            stateCopy.remainQuestion = [];
            stateCopy.showQuiz1 = false;
            stateCopy.questions = questions;
            return stateCopy;
        case 'Get_Resource_Liked':
            stateCopy.getIndividualResource.liked = !action.payload.liked;
            return stateCopy;
        default:
            return stateCopy
    }
}

const getComplete = (topics, content) => {
    for (var i = 0; i < topics.length; i++) {
        var contentIndex = content[i];
        for (var j = 0; j < contentIndex.length; j++) {
            temp = true;
            if (topics[i].topic === contentIndex[j].topic) {
                if (!contentIndex[j].viewed) {
                    temp = false;
                    break;
                }
            }

        }
        topics[i].complete = temp
    }
    return topics
}